body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* FADE IN */

.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
  }
  @keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }


  /* LOADING */


  .lds-dual-ring {
    display: inline-block;
    width: 20px;
    height: 20px;
    padding: 0 10px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 16px;
    height: 16px;
    margin: 2px;
    border-radius: 50%;
    border: 1px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }


  /* SHOW AFTER 2s */
  .hide-now {
    animation: cssAnimation 0s 2s forwards;
    visibility: hidden; 
  }

  @keyframes cssAnimation {
    to   { visibility: visible; }
  }