@media only screen and (orientation: portrait), screen and (max-width: 1680px) {
  .App {
    background-size: auto 100%;
  }
}


.youtube {
  animation: shake 2s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}


@keyframes shake {
  0% { transform: translate(5px, 5px) rotate(0deg); }
  50% { transform: translate(-5px, -2px) rotate(-5deg); }
  20% { transform: translate(-3px, 0px) rotate(5deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(5px, -5px) rotate(5deg); }
  50% { transform: translate(-5px, 2px) rotate(-5deg); }
  60% { transform: translate(-3px, 5px) rotate(0deg); }
  70% { transform: translate(3px, 5px) rotate(-5deg); }
  80% { transform: translate(-5px, -5px) rotate(5deg); }
  90% { transform: translate(5px, 2px) rotate(0deg); }
  500% { transform: translate(5px, 5px) rotate(0deg); }
}

.hover {
  cursor: pointer;
}